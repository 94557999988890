//selector = id ou class du formulaire
function ajaxForm(form) {
    // Loader
    $.ajax({
        url: form.attr('action'),
        type: form.attr('method'),
        data: new FormData(form[0]),
        processData: false,
        contentType: false,
        async: true,
        statusCode: {
            //traitement en cas de succès
            200: function (response) {

                //on vide les champs formulaires ou on le supprime ou ...
                form.trigger("reset");

                // Fermeture des modales
                if(form.attr('data-close-modal')){
                    $('#' + $('.modal.fade.in').attr('id')).modal('hide');
                }

                //on prévient l'utilistateur via une/des notification(s)
                notif(response.notif);

                // Remplacement du formulaire par un nouveau
                var resetForm = response.formReset;
                form.html(resetForm);

                // Actualisation du bloc de résultat
                var changeBlock = form.attr('data-changeblock');
                var route = form.attr('data-route');

                if(changeBlock != "disable"){
                    if (changeBlock && route) {
                        ajaxGetContent(changeBlock, route)
                    } else {
                        notif({"type":"warning","title":"Erreur contenu","message":"Actualisation impossible"});
                    }
                }

            },
            //traitement en cas d'erreur (on peut aussi traiter le cas erreur 500...)
            412: function (response) {
                var errorsForm = response.responseJSON.formErrors;
                form.html(errorsForm);
                notif({"type":"error","title":"Erreur","message":"Erreur de données dans le formulaire"});
                if (response.responseJSON.notif) {
                    notif(response.responseJSON.notif);
                }
            },
            500: function (response) {
                notif({"type":"error","title":"Erreur","message":"Erreur de traitement"});
                console.log(500);
                console.log(response);
            }
        }

    });
}

function ajaxGetContent(selector, route) {
    if (route) {
        $.ajax({
            type: "GET",
            url: route,
            async: true,
            statusCode: {
                //traitement en cas de succès
                200: function (response) {
                    $(selector).html($(response));
                },
                //traitement en cas d'erreur (on peut aussi traiter le cas erreur 500...)
                412: function (response) {
                    var errorsForm = response.responseJSON.formErrors;
                    notif({"type":"error","title":"Erreur","message":"Erreur de données dans le formulaire"});
                },
                500: function () {
                    var errorsForm = "Erreur 500";
                    notif({"type":"error","title":"Erreur","message":"Erreur de données dans le formulaire"});
                    console.log(500);
                    console.log(errorsForm);
                }
            }
        });
    }
}