$( document ).ready(function() {
    $('.navbar').on('keyup', function(e){
        if(e.keyCode == 9) {
            $('#algolia-autocomplete-listbox-0 .aa-dataset-1 .aa-suggestions .aa-suggestion:first-child a').focus();
        }
    });

    $('#algolia-autocomplete-listbox-0').on('keyup', function() {
        $('.aa-dropdown-menu').css('display', 'block');
        var number_of_last_element = 6;
        while($('#algolia-autocomplete-listbox-0 .aa-dataset-' + number_of_last_element + ' .aa-suggestions .aa-suggestion:last-child a').text() == "") {
            number_of_last_element--;
        }

        var last_element = $('#algolia-autocomplete-listbox-0 .aa-dataset-' + number_of_last_element +' .aa-suggestions .aa-suggestion:last-child a').attr('href');
        var active_element = document.activeElement;
        var split_active_element = String(active_element).split(".com");
        if(split_active_element[1] == last_element) {
            $('#algolia-autocomplete-listbox-0').bind('keydown', function returnOnFirstElement(e) {
                if (e.keyCode == 9) {
                    $('#algolia-autocomplete-listbox-0 .aa-dataset-1 .aa-suggestions .aa-suggestion:first-child a').focus();
                }
                $('#algolia-autocomplete-listbox-0').unbind('keydown', returnOnFirstElement);
            });
        }
    });

    $(document).click(function(event) {
        if(!$(event.target).closest('#algolia-autocomplete-listbox-0').length) {
            $('#algolia-autocomplete-listbox-0').css('display', 'none');
        }
    });

});