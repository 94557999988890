/* Utilitaire d'impersonification, besoin du plugin typeahead*/

/* Attention utilise une variable dans le fichier base.html.twig (fosImpersonateEnable) */

var impersonate = $("input#impersonate");
impersonate.on('click', function () {

    $.get(Routing.generate('impersonate'), function (data) {
        impersonate.typeahead({
            source: data,
            afterSelect: function (item) {

                var chemin_complet = document.location.href.split("/");

                if(fosImpersonateEnable){
                    window.location.href = Routing.generate('user_logout_and_switch', {'username':item.username}, true) + "?_switch_user=_exit";
                } else {
                    if(chemin_complet[3] == "lot" && chemin_complet[4] == "programme") {
                        var id = chemin_complet[5];
                        window.location.href = '/partenaires/lot/programme/' + id + "?_switch_user=" + item.username;
                    } else if(chemin_complet[3] == "lot" && chemin_complet[4] == "redirect_to_lots") {
                        var id = chemin_complet[5];
                        window.location.href = '/partenaires/lot/programme/' + id + "?_switch_user=" + item.username;
                    } else if(chemin_complet[3] == "programme" && chemin_complet[4] != "") {
                        var id = chemin_complet[4];
                        window.location.href = '/partenaires/programme/' + id + "?_switch_user=" + item.username;
                    } else {
                        if(item.type === "partenaire"){
                            window.location.href = Routing.generate('partenaires_index', '', true) + "?_switch_user=" + item.username;
                        } else if (item.type === "admin"){
                            window.location.href = Routing.generate('adminHome', '', true) + "?_switch_user=" + item.username;
                        }
                    }
                }

            }
        });
    }, 'json');
});

$('.typeahead li').css('visibility', 'hidden');

impersonate.on('keydown', function(e) {
    if(e.keyCode == "37" || e.keyCode == "38" || e.keyCode == "39" || e.keyCode == "40") {
        $('.typeahead').css('visibility', 'visible');
    } else {
        $('.typeahead').css('visibility', 'hidden');
    }
});

impersonate.on('keyup', function() {
    $('.dropdown-item').each(function() {
        var slug = $(this).text().split(")");
        $('.typeahead').css('visibility', 'visible');

        if(slug[1] != "") {
            var texte = slug[0] + ') <span style="visibility:hidden;">' + slug[1] + '</span>';
            $(this).html(texte);
        }
    });
});

// Focus input impersonate
Mousetrap.bind(['ctrl+i', 'command+i'], function(e) {
    document.getElementById("impersonate").focus();
});
