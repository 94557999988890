    $(document).ready(function() {
        // Lien TR
        $('.table-responsive').on('draw.dt', function () {
            $('tr td').on('click', function () {
                switch (app_current_route) {
                    case "promoteur_programme_index":
                        goToFiche(this, 'promoteur_programme_show');
                        break;
                    case "promoteur_entitecommerciale_index":
                        goToFiche(this, 'promoteur_entitecommerciale_show');
                        break;
                    case "promoteur_contact_index":
                        goToFiche(this, 'promoteur_contact_show');
                        break;
                    case "dossiertransaction_index":
                        goToFiche(this, 'dossiertransaction_show');
                        break;
                    case "dossiertransaction_edit":
                        goToFiche(this, 'dossiertransaction_edit');
                        break;
                    case "promoteur_lot_index":
                        goToFiche(this, 'promoteur_lot_show');
                        break;
                    case "partenaires_dossiertransaction_edit":
                        goToFiche(this, 'partenaires_dossiertransaction_edit');
                        break;
                    case "demandefinancement_index":
                        goToFiche(this, 'demandefinancement_edit');
                        break;
                    case "promoteur_annuaire_index":
                        goToFiche(this, 'promoteur_annuaire_show');
                        break;
                    case "promoteur_passerelle_index":
                        goToFiche(this, 'promoteur_passerelle_show');
                        break
                    case "phone_index":
                        goToFiche(this, 'phone_show');
                        break;
                    case "user_index":
                        goToFiche(this, 'user_show');
                        break;
                    case "promoteur_banniere_index":
                        goToFiche(this, 'promoteur_banniere_show');
                        break;
                    case "promoteur_programme_lot_index":
                        goToFiche(this, 'promoteur_lot_show');
                        break;
                    case "promoteur_prospect_index":
                        goToFiche(this, 'promoteur_prospect_show');
                        break;
                    case "promoteur_campaign_index":
                        goToFiche(this, 'promoteur_campaign_show');
                        break;
                }
            });
        });

        function goToFiche(cell, route) {
            if (cell.firstElementChild == null) {
                window.location.href = Routing.generate(route, {'id': cell.parentElement.id});
            }
        }
    });
