$(document).ready(function () {
    // Annulation du dossier de transaction
    $('.btn-annuler-dt').on('click', function () {
        var dossierTransactionId = $(this).data('dossier-transaction-id');
        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Pour une prolongation de votre option, merci de contacter votre assistante',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, annuler !'
        }).then((result) => {
            if (result.value) {
                window.location.replace(
                    Routing.generate(
                        'partenaires_dossiertransaction_annulation',
                        {
                            dossierTransaction: dossierTransactionId,
                            referrer: app_current_route
                        },
                        true
                    )
                );
            } else {
                console.log('annulé');
            }
        });
    });

    // Suppriession délégation
    $('.promoteur-supprimer-delegation').on('click', function () {
        var delegant = $(this).data('delegant');
        var delegataire = $(this).data('delegataire');

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez supprimer une délégation',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (result.value) {
                window.location.replace(
                    Routing.generate(
                        'promoteur_supprimer_delegation',
                        {
                            delegant: delegant,
                            delegataire: delegataire
                        },
                        true
                    )
                );
            } else {
                console.log('annulé');
            }
        });
    });

    // Suppression Document
    $('.promoteur-delete-document').on('click', function () {
        const docId = $(this).parents('tr').attr('data-documentid');

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez supprimer un document',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (result.value) {
                window.location.replace(Routing.generate('promoteur_vade_mecum_delete', { id: docId }, true));
            }
        });
    });

    // Envoie Mandat
    $('.promoteur-send-mandat').on('click', function (e) {
        e.preventDefault();

        swal({
            title: 'Avez-vous vérifié le taux de com ?',
            text: 'Vous allez envoyer un mandat',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#1ab394',
            confirmButtonText: 'Oui, Envoyer'
        }).then((result) => {
            if (!result.value) return;

            $(this).off('click');
            window.location.href = $(this).attr('href');
        });
    });

    // Suppression Mandat
    $('.promoteur-delete-mandat').on('click', function () {
        var mandatid = $(this).parents('mandat-id').context.id;

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez supprimer un mandat',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (!result.value) return;

            window.location.replace(Routing.generate('promoteur_mandat_delete', { mandat: mandatid }, true));
        });
    });

    // Suppression plan pmr
    $('.delete_pmr').on('click', function () {
        var lotid = $(this).parents('plan-id').context.id;

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez supprimer ce plan',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (result.value) {
                window.location.replace(Routing.generate('partenaires_lot_remove_plan_pmr', { lot: lotid }, true));
            } else {
                console.log('annulé');
            }
        });
    });

    // Suppression plan
    $('.delete_plan').on('click', function () {
        var lotid = $(this).parents('plan-id').context.id;

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez supprimer ce plan ',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (result.value) {
                window.location.replace(Routing.generate('partenaires_lot_remove_plan', { lot: lotid }, true));
            } else {
                console.log('annulé');
            }
        });
    });

    // Suppression de tout les plans PMR
    $('.delete_all_plan').on('click', function () {
        var programmeid = $(this).parents('programme-id').context.id;

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez supprimer tous les plans',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (result.value) {
                window.location.replace(Routing.generate('partenaires_lot_remove_plan_all_pmr', { programme: programmeid }, true));
            } else {
                console.log('annulé');
            }
        });
    });

    // Suppression de tout les plans non PMR
    $('.delete_all_plan_non_pmr').on('click', function () {
        var programmeid = $(this).parents('programme-id').context.id;

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez supprimer tous les plans',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (result.value) {
                window.location.replace(Routing.generate('partenaires_lot_remove_plan_all_non_pmr', { programme: programmeid }, true));
            } else {
                console.log('annulé');
            }
        });
    });

    // Suppression Mandat
    $('.promoteur-suppression-entiteCommerciale').on('click', function () {
        var ecid = $(this).parents('entiteCommerciale-id').context.id;

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez supprimer une Entite Commerciale',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (result.value) {
                window.location.replace(Routing.generate('promoteur_delete_entiteCommerciale', { entiteCommerciale: ecid }, true));
            } else {
                console.log('annulé');
            }
        });
    });

    // Suppression Mandat
    $('.promoteur-delete-mandat').on('click', function () {
        var mandatid = $(this).parents('mandat-id').context.id;

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez supprimer un mandat',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (result.value) {
                window.location.replace(Routing.generate('promoteur_mandat_delete', { mandat: mandatid }, true));
            } else {
                console.log('annulé');
            }
        });
    });

    // Suppriession délégation
    $('#promoteur-supprimer-contact').on('click', function (e) {
        e.preventDefault();

        var form = $(this).parents('form');

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez supprimer un contact',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#d33',
            confirmButtonText: 'Je confirme la suppression',
            closeOnConfirm: false
        }).then((result) => {
            if (result.value) {
                if (isConfirm) form.submit();
            } else {
                console.log('annulé');
            }
        });
    });

    $('#promoteur-annulation-dossierTransaction').on('click', function () {
        swal({
            title: 'Êtes-vous sûr ?',
            text: '',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, annuler !'
        }).then((result) => {
            if (result.value) {
                window.location.replace(
                    Routing.generate(
                        'promoteur_dossiertransaction_annulation',
                        {
                            dossierTransaction: $('#dtInfos').data('dossier-transaction-id')
                        },
                        true
                    )
                );
            } else {
                console.log('annulé');
            }
        });
    });
    $('#partenaire-passer-en-reserver_cnr').on('click', function () {
        var type_vente = $('#appbundle_partenaires_dossiertransaction_type_vente').val();
        if (type_vente == 'pinel' || type_vente == 'accession') {
            swal({
                title: 'Etes-vous sûr de vouloir continuer?\n',
                html: '<h1>Une fois le lot réservé, vous ne pourrez plus modifier ou ajouter les options du Pack Confort</h1>' + "<p>Veuilez confirmer l'option fiscale</p>",
                input: 'select',

                inputOptions: {
                    accession: 'Accession',
                    pinel: 'Investissement Pinel'
                },
                inputAttributes: {
                    autocapitalize: 'off'
                },
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: 'NON retour fiche lot',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OUI je réserve'
            }).then((result) => {
                if (result.value) {
                    var value = result.value;
                    window.location.replace(
                        Routing.generate(
                            'partenaires_dossiertransaction_reserver_cnr',
                            {
                                type: value,
                                dossierTransaction: $('#dtInfos').data('dossier-transaction-id')
                            },
                            true
                        )
                    );
                } else {
                    console.log('annulé');
                }
            });
        } else {
            swal({
                title: 'Etes-vous sûr de vouloir continuer?\n',
                html: '<h1>Une fois le lot réservé, vous ne pourrez plus modifier ou ajouter les options du Pack Confort</h1>' + "<p>Veuilez confirmer l'option fiscale</p>",
                input: 'select',

                inputOptions: {
                    lmnp_ht: 'LMNP HT',
                    lmnp_ttc: 'LMNP TTC',
                    lmp_ht: 'LMP HT',
                    lmp_ttc: 'LMP TTC'
                },
                inputAttributes: {
                    autocapitalize: 'off'
                },
                type: 'warning',
                showCancelButton: true,
                cancelButtonText: 'NON retour fiche lot',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OUI je réserve'
            }).then((result) => {
                if (result.value) {
                    var value = result.value;
                    window.location.replace(
                        Routing.generate(
                            'partenaires_dossiertransaction_reserver_cnr',
                            {
                                type: value,
                                dossierTransaction: $('#dtInfos').data('dossier-transaction-id')
                            },
                            true
                        )
                    );
                } else {
                    console.log('annulé');
                }
            });
        }
    });

    $('#promoteur-passer-en-reserver_cnr').on('click', function () {
        var type_vente = $('#appbundle_promoteur_dossiertransaction_type_vente').val();
        if (type_vente == 'pinel' || type_vente == 'accession') {
            Swal.fire({
                title: 'Êtes-vous sûr ?',
                type: 'warning',
                html: '<h1>Vous allez passer le statut en "Réservé CNR" avec un mode signature papier</h1>' + "<p>Veuilez confirmer l'option fiscale</p>",
                input: 'select',
                inputOptions: {
                    accession: 'Accession',
                    pinel: 'Investissement Pinel'
                },
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonText: 'Fermer',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, passer en "Réservé CNR"'
            }).then((result) => {
                if (result.value) {
                    var value = result.value;
                    var lot = $('#cnr-lot').html();
                    var dossier = $('#cnr-dossier').html();
                    var conseiller = $('#cnr-conseiller').html();

                    //window.location.href = '/partenaires/dossier/transaction/lot/' + lot + '/dossier/transaction/' + dossier + '/edit' + "?_switch_user=" + conseiller;

                    window.location.replace(
                        Routing.generate(
                            'promoteur_dossiertransaction_reserver_cnr',
                            {
                                type: value,
                                dossierTransaction: $('#dtInfos').data('dossier-transaction-id')
                            },
                            true
                        )
                    );
                } else {
                    console.log('annulé');
                }
            });
        } else {
            Swal.fire({
                title: 'Êtes-vous sûr ?',
                type: 'warning',
                html: '<h1>Vous allez passer le statut en "Réservé CNR" avec un mode signature papier</h1>' + "<p>Veuilez confirmer l'option fiscale</p>",
                input: 'select',
                inputOptions: {
                    lmnp_ht: 'LMNP HT',
                    lmnp_ttc: 'LMNP TTC',
                    lmp_ht: 'LMP HT',
                    lmp_ttc: 'LMP TTC'
                    /*censi_bouvard_ht: 'CENSI-BOUVARD HT',
                    censi_bouvard_ttc: 'CENSI-BOUVARD TTC'*/
                },
                inputAttributes: {
                    autocapitalize: 'off'
                },
                showCloseButton: true,
                showCancelButton: true,
                cancelButtonText: 'Fermer',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Oui, passer en "Réservé CNR"'
            }).then((result) => {
                if (result.value) {
                    var value = result.value;
                    var lot = $('#cnr-lot').html();
                    var dossier = $('#cnr-dossier').html();
                    var conseiller = $('#cnr-conseiller').html();

                    //window.location.href = '/partenaires/dossier/transaction/lot/' + lot + '/dossier/transaction/' + dossier + '/edit' + "?_switch_user=" + conseiller;

                    window.location.replace(
                        Routing.generate(
                            'promoteur_dossiertransaction_reserver_cnr',
                            {
                                type: value,
                                dossierTransaction: $('#dtInfos').data('dossier-transaction-id')
                            },
                            true
                        )
                    );
                } else {
                    console.log('annulé');
                }
            });
        }
    });

    $('#promoteur-passer-en-reserver').on('click', function () {
        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez passer le statut du dossier en "Réservé"',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Oui, passer en "Réservé"'
        }).then((result) => {
            if (result.value) {
                window.location.replace(
                    Routing.generate(
                        'promoteur_dossiertransaction_reserver',
                        {
                            dossierTransaction: $('#dtInfos').data('dossier-transaction-id')
                        },
                        true
                    )
                );
            } else {
                console.log('annulé');
            }
        });
    });

    // Suppression de signature
    $('.partenaire-signature-cancel').on('click', function () {
        var signatureid = $(this).parents('signature-id').context.id;

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez annuler la signature',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Annuler la signature'
        }).then((result) => {
            if (result.value) {
                window.location.replace(Routing.generate('partenaire_signature_cancelv2', { signature: signatureid }, true));
            } else {
                console.log('annulé');
            }
        });
    });

    $('.promoteur-annulation-signature').on('click', function () {
        var signatureid = $(this).parents('signature-id').context.id;

        swal({
            title: 'Êtes-vous sûr ?',
            text: 'Vous allez annuler la signature',
            type: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Fermer',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Annuler la signature'
        }).then((result) => {
            if (result.value) {
                window.location.replace(Routing.generate('promoteur_signature_cancel', { signature: signatureid }, true));
            } else {
                console.log('annulé');
            }
        });
    });

    $('#promoteur-passer-en-vendu').on('click', function () {
        var dateVente = $('#dateVente').text();
        var prix = $('#prixTotal').text();
        prix = prix.split(',');
        prix_modifier = prix[0];
        var reg = /\s|,|-/g;
        prix_modifier = prix_modifier.replace(reg, '');

        Swal.mixin({
            type: 'warning',
            title: 'Êtes-vous sûr ?',
            input: 'text',
            confirmButtonText: 'Suivant &rarr;',
            showCancelButton: true,
            progressSteps: ['1', '2'],
            inputAttributes: {
                autocapitalize: 'off'
            },
            cancelButtonText: 'Annuler',
            showLoaderOnConfirm: true,
            preConfirm: (value) => {
                return value;
            },
            allowOutsideClick: () => !Swal.isLoading()
        })
            .queue([
                {
                    title: 'Vous allez passer le statut du dossier en "Vendu" ',
                    text: 'Vous devez recopier le montant total : ' + prix
                },
                {
                    title: 'Rentrez la date de vente',
                    text: 'Sous la forme JJ/MM/AAAA'
                }
            ])
            .then(function (result) {
                if (result.value) {
                    console.log('Prix attendu : ' + prix_modifier);
                    console.log('Prix entré : ' + result.value);
                    const answers = JSON.stringify(result.value);
                    const prix_entre = result.value[0];
                    const date_vente = result.value[1];

                    var regex = /^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/g;

                    if (prix_entre == prix_modifier && regex.test(date_vente)) {
                        window.location.replace(
                            Routing.generate(
                                'promoteur_dossiertransaction_vendu',
                                {
                                    dossierTransaction: $('#dtInfos').data('dossier-transaction-id'),
                                    dateVente: date_vente
                                },
                                true
                            )
                        );
                    } else {
                        Swal.fire({
                            title: 'Erreur',
                            type: 'error',
                            html:
                                '<h1>Il y a une erreur sur le prix ou date incorrecte</h1>' +
                                '<p>Montant attendu :<strong>' +
                                prix_modifier +
                                '</strong></p>' +
                                '<p>Montant rentré : <strong>' +
                                prix_entre +
                                '</strong></p>'
                        });
                    }
                }
            });
    });

    var url = new URL(window.location.href);
    var popup = url.searchParams.get('popup');

    if (popup === 'signature_electronique_validation') {
        swal(
            {
                title: 'Le lien de signature a bien été envoyé au(x) client(s), vous allez recevoir une confirmation par mail. Merci pour votre confiance.',
                type: 'success',
                showCancelButton: true,
                cancelButtonText: 'Fermer',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33'
            },
            function closeInfoPopup() {
                setTimeout(function () {
                    window.fadeOut();
                }, 3000);
            }
        );
    }
});
