$(document).ready(function(){

// Instance the tour
var tour = new Tour({
    steps: [{
        element: "#etape1",
        title: Translator.trans("tour.etape1.titre"),
        content: Translator.trans("tour.etape1.message"),
        backdrop: true,
        backdropContainer: '#wrapper',
        onShown: function (tour){
            $('body').addClass('tour-open')
        },
        onHidden: function (tour){
            $('body').removeClass('tour-close')
        }

    }, {
        element: "#etape2",
        title: Translator.trans("tour.etape2.titre"),
        content: Translator.trans("tour.etape2.message"),
        backdrop: true,
        backdropContainer: '#wrapper',
        onShown: function (tour){
            $('body').addClass('tour-open')
        },
        onHidden: function (tour){
            $('body').removeClass('tour-close')
        }
    }, {
        element: "#etape3",
        title: Translator.trans("tour.etape3.titre"),
        content: Translator.trans("tour.etape3.message"),
        backdrop: true,
        backdropContainer: '#wrapper',
        onShown: function (tour){
            $('body').addClass('tour-open')
        },
        onHidden: function (tour){
            $('body').removeClass('tour-close')
        }
    }, {
        element: "#etape4",
        title: Translator.trans("tour.etape4.titre"),
        content: Translator.trans("tour.etape4.message"),
        backdrop: true,
        backdropContainer: '#wrapper',
        onShown: function (tour){
            $('body').addClass('tour-open')
        },
        onHidden: function (tour){
            $('body').removeClass('tour-close')
        }
    }, {
        element: "#etape5",
        title: Translator.trans("tour.etape5.titre"),
        content: Translator.trans("tour.etape5.message"),
        backdrop: true,
        backdropContainer: '#wrapper',
        onShown: function (tour){
            $('body').addClass('tour-open')
        },
        onHidden: function (tour){
            $('body').removeClass('tour-close')
        }
    }, {
        element: "#etape6",
        title: Translator.trans("tour.etape6.titre"),
        content: Translator.trans("tour.etape6.message"),
        backdrop: true,
        backdropContainer: '#wrapper',
        onShown: function (tour){
            $('body').addClass('tour-open')
        },
        onHidden: function (tour){
            $('body').removeClass('tour-close')
        }
    }, {
        element: "#etape7",
        title: Translator.trans("tour.etape7.titre"),
        content: Translator.trans("tour.etape7.message"),
        backdrop: true,
        backdropContainer: '#wrapper',
        onShown: function (tour){
            $('body').addClass('tour-open')
        },
        onHidden: function (tour){
            $('body').removeClass('tour-close')
        }
    }, {
        element: "#etape8",
        title: Translator.trans("tour.etape8.titre"),
        content: Translator.trans("tour.etape8.message"),
        backdrop: true,
        backdropContainer: '#wrapper',
        onShown: function (tour){
            $('body').addClass('tour-open')
        },
        onHidden: function (tour){
            $('body').removeClass('tour-close')
        }
    }],
    storage: false,
    template: "<div class='popover tour'>\n" +
    "    <div class='arrow'></div>\n" +
    "    <h3 class='popover-title'></h3>\n" +
    "    <div class='popover-content'></div>\n" +
    "    <div class='popover-navigation'>\n" +
    "        <button class='btn btn-default' data-role='prev'>« Préc</button>\n" +
    "        <button class='btn btn-default' data-role='next'>Suiv »</button>\n" +
    "        <button class='btn btn-default' data-role='end'>Fermer</button>\n" +
    "    </div>" +
    "  </div>"
}).init();

$('#startTour-signature').click(function(){
    tour.init();
    tour.restart();
})

});