$(document).ready(function () {
    // Déclanche le moteur de prix
    if ($('form[name="appbundle_partenaires_dossiertransaction"]').length > 0) {
        if(env === 'dev') {
            console.log('Initialisation Moteur prix');
        }

        setMontantLabel();
        majEurosInput();
        calculTotal();
    }

    $("#prix-lot").on('change', function (e) {
        calculTotal();
    });

    // Maj du prix a la selection d'options
    $('form[name="appbundle_partenaires_dossiertransaction"] :input[data-montant]').on('change',function(){
        calculTotal();
    });

    /*
     *   Calcul du total du lot, aucun effet sur le prix total de base de données
     *   Côté php voir l'event listener
     */
    function calculTotal() {
        totalFrais = 0;
        itemTotal = $('#prix-total');

        prixLot = $('#prix-lot').data('montant');

        remise = $('#prix-remise').data('montant');

        $('form[name="appbundle_partenaires_dossiertransaction"] :input[data-montant]:checked').each(function() {
            frais = $(this).data('montant');

            if(!frais) {
                frais = 0;
            }

            if(env === 'dev') {
                console.log(frais);
            }

            totalFrais = totalFrais + frais;
        });

        var value_garantie_locative = $("#appbundle_partenaires_dossiertransaction_prix_frais_opts_garanties_locatives_selectionner").val();

        if(value_garantie_locative == 1){
            var new_frais = ($("#appbundle_partenaires_dossiertransaction_prix_frais_opts_garanties_locatives_selectionner").data("montant"));
            totalFrais = totalFrais + new_frais ;
        }

        if(!totalFrais) {
            totalFrais = 0;
        }

        if(!prixLot) {
            prixLot = 0;
        }

        if(!remise) {
            remise = 0;
        }


        total = totalFrais + prixLot + remise;

        totalFormater =  new Intl.NumberFormat('fr-FR',{style: "currency", currency: "EUR"}).format(total);

        itemTotal.text(totalFormater);
        itemTotal.data('total',total);
        itemTotal.animateCss('bounceIn');

        if(env === 'dev') {
            console.log('Prix lot : ' + prixLot);
            console.log('Montant remise : ' + remise);
            console.log('Total : ' + total);
        }
    }

    // Formatage euros
    function majEurosInput(){
        $('input.euros').each(function() {
            montant = $(this).val();
            montant =  new Intl.NumberFormat('fr-FR',{style: "currency", currency: "EUR"}).format(montant);
            $(this).val(montant);
        });
    }

    // Ajout des label montant€ sur le formulaire
    function setMontantLabel(){
        $('form[name="appbundle_partenaires_dossiertransaction"] :input[data-montant]').each(function() {
            inputName = $(this).attr('name');
            label = $(this).parent();
            montant = $(this).data('montant');
            montantFormater =  new Intl.NumberFormat('fr-FR',{style: "currency", currency: "EUR"}).format(montant);

            label.html(label.html().replace(/%montant%/g, montantFormater));
        });
    }
});