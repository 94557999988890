
/*
 * Gestion de l'affichage de l'assistante
 */
$(document).ready(function () {
    if (window.matchMedia("(max-width: 768px)").matches) {
        $('#assistante-row').hide();
        $('#assistante-flat').click(function () {
            $('#assistante-row').slideToggle(500);
        });
    } else {
        if (readCookie('assistante') == null) {
            $('#assistante-row').slideToggle('show');
        }
        $('#assistante-flat').click(function () {
            $('#assistante-row').slideToggle(500);
            if (readCookie('assistante') == null) {
                createCookie('assistante', false, 7);
            } else {
                eraseCookie('assistante');
            }
        });
    }
});