$(window).scroll(function(){
    if($(window).scrollTop() == 0) {
        $('.backtotop').hide();
    } else {
        $('.backtotop').show();
    }
});

$(document).ready(function() {
    $('.backtotop').hide();
    $('.backtotop').on('click', function () {
        backToTop();
    });
});

function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}